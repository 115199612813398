import { ACPVariant } from 'apps/acp/variants/acp-variant';

const variant: ACPVariant = {
  id: 'www.everydayselectrewards.com',
  productTitle: 'Everyday Select Rewards Visa Prepaid Card',
  productType: 'gpr',
  accessDomain: {
    subdomain: 'www',
    domain: 'everydayselectrewards',
    topLevelDomain: 'com'
  },
  colors: {
    neutral: {
      color50: '#f3f3f3',
      color100: '#e6e6e6',
      color200: '#cccccc',
      color300: '#b3b3b3',
      color400: '#999999',
      color500: '#808080',
      color600: '#666666',
      color700: '#4d4d4d',
      color800: '#333333',
      color900: '#1a1a1a'
    },
    default: {
      color50: '#d5d5d5',
      color100: '#aaaaaa',
      color200: '#808080',
      color300: '#555555',
      color400: '#2b2b2b',
      color500: '#000000',
      color600: '#000000',
      color700: '#000000',
      color800: '#000000',
      color900: '#000000'
    },
    accent: {
      color50: '#f7f3eb',
      color100: '#efe7d7',
      color200: '#e7dcc4',
      color300: '#ded0b0',
      color400: '#d6c49c',
      color500: '#ceb888',
      color600: '#a5936d',
      color700: '#7c6e52',
      color800: '#524a36',
      color900: '#29251b'
    },
    positive: {
      color50: '#ebf4d5',
      color100: '#d6e9aa',
      color200: '#c2de80',
      color300: '#add355',
      color400: '#99c82b',
      color500: '#84bd00',
      color600: '#6a9700',
      color700: '#4f7100',
      color800: '#354c00',
      color900: '#1a2600'
    },
    negative: {
      color50: '#f8d5dd',
      color100: '#f1aabb',
      color200: '#ea8099',
      color300: '#e35576',
      color400: '#dc2b54',
      color500: '#d50032',
      color600: '#aa0028',
      color700: '#80001e',
      color800: '#550014',
      color900: '#2b000a'
    },
    special: {
      color50: '#d5edf4',
      color100: '#aadbe9',
      color200: '#80c9de',
      color300: '#55b6d2',
      color400: '#2ba4c7',
      color500: '#0092bc',
      color600: '#007596',
      color700: '#005871',
      color800: '#003a4b',
      color900: '#001d26'
    }
  },
  legacyAcpTheme: 'albertsons',
  legacyAcpBrandName: 'albertsons_gpr_republic',
  legacyLegosTheme: 'albertsons',
  status_bar_background_color: '#000000',
  status_bar_foreground_color: 'light',
  contactInfo: {
    name: 'Everyday Select Rewards Visa Prepaid Card',
    contact_address_line1: 'PO Box 2136',
    contact_address_line2: 'Austin, TX 78768-2136',
    contact_address_line3: '',
    customer_support_email: 'CustomerService@netspend.com',
    fax_formatted: '(866) 358-0526',
    phone_formatted: '(877) 849-3247',
    phone: '8778493247',
    phone_international: '737-220-8956',
    balance_check_phone: '8778493247',
    balance_check_phone_formatted: '1-877-849-3247',
    pre_funded_checks_phone: '18778147683',
    pre_funded_checks_phone_formatted: '1-877-814-7683'
  },
  configuration: {
    apiKeys: {
      appsFlyerDevKey: '5Us7NeWk6WVjGLsAnKioSk',
      appsFlyerBannerKey: '1d16e6b1-8bb0-4188-9e4c-ca04d451e467',
      'google-tag-manager-container-id': 'GTM-NQXKPD',
      'google-tag-manager-container-id-mobile': 'GTM-MWBTT5M',
      paypalFraudNetPayerIdProd: '',
      paypalFraudNetPayerIdTest: ''
    },
    featureToggles: {
      activationSetExternalId: false,
      appsFlyerEnabled: false,
      combinedTransactionsMasterAndSub: false,
      'embedded-activation': true,
      embeddedPayNearMe: false,
      hasNotificationCenter: false,
      mobilePBREnabled: true,
      preOnboardingEnabled: false,
      showAccountDisplayNickname: false,
      showAcquisitionLink: true,
      showATMFriendlyCards: false,
      showDisplayNameForSubAccount: false,
      showEmailInformationButton: true,
      showFeePlanPage: false,
      showHowToEarnRewardPointLink: true,
      showRegisterLink: true,
      showScanCardRegistration: true,
      showMarketingSiteLink: true,
      showFreeAtmFinderLink: false,
      showWUTransfers: true,
      showReloadLocationLink: false
    },
    misc: {
      distributor: 'albertsons',
      layoutComponentOverride: false,
      loader: 'albertsons',
      payNearMeButtonTextColor: '#FFFFFF',
      payNearMeActionBarBackgroundColor: '#0A4D8C',
      payNearMeTitleTextColor: '#FFFFFF',
      payNearMeColorAccent: '#84bd00',
      marketingSiteUrl: 'https://www.everydayselectrewards.com',
      marketingSiteLinkText: 'EverydaySelectRewards.com',
      locationSearchMax: '25',
      iOSAppId: '1377331093'
    },
    content: {
      signUpContent: "Don't have a card? Sign up today."
    },
    disclaimers: {},
    sections: [
      'dashboard-gpr',
      'dashboard-lite',
      'dashboard-handoff',
      'layout',
      'manage-gpr',
      'move-money',
      'overdraft',
      'locations',
      'direct-deposit',
      'direct-deposit-mobile-panel',
      'statements',
      'contact-us',
      'restrictions-readonly',
      'feeplan',
      'western-union',
      'disclosure-dictionary',
      'check-deposit',
      'checks',
      'quickbooks-oauth',
      'points',
      'post-handoff-to-activate-register',
      'upgrade',
      'offers',
      'offers-intro',
      'tours',
      'post-install-multi-screen-acquisition-flow',
      'echecks',
      'external-loyalty',
      'secure-inbox',
      'benefit-center',
      'atm-finder',
      'savings',
      'pay-bills',
      'premier-plan',
      'activation',
      'savings-transfer',
      'anytime-alerts',
      'anytime-alerts-upgrade',
      'change-username',
      'change-password',
      'mobile-check-load',
      'feedback',
      'rent-track',
      'direct-deposit-form',
      'dynamic-faq',
      'billpay',
      'card',
      'refer-a-friend',
      'close-account',
      'points-learn-more',
      'family-cards',
      'bank-transfers',
      'handoff',
      'debit-card-transfers',
      'card-use-settings',
      'secure-upload',
      'digital-wallet',
      'me-to-me-transfer',
      'money-gram',
      'survey',
      'debit-card-transfers-disbursement',
      'spend-money',
      'fraud-detection',
      'spending-tracker',
      'connected-banks',
      'interstitials',
      'dd-tracker'
    ]
  }
};

export default variant;
